<template>
  <div>
    <!-- SELECT ENTRY -->
    <template v-if="!$route.query.page">
      <b-row class="mb-2">
        <b-col cols="12" md="6">
          <b-input-group prepend="Entry:">
            <b-form-select 
              v-model="selectedPage" 
              :options="contentOptions"
              class="w-auto"
              @change="$router.push({ name: 'Pages', params: { action: selectedPage === 'new' ? null :'edit', slug: selectedPage } })"
            />
          </b-input-group>
        </b-col>
        <b-col v-if="selectedPage" cols="12" md="6">
          <!-- page -->
          <b-input-group v-if="selectedPage !== 'sport'" prepend="Parent:" class="animated fadeIn">
            <b-form-select 
              v-model="selectedParentPage" 
              :options="parentContentOptions"
              class="w-auto"
            />
          </b-input-group>
          <!-- sports-we-play content -->
          <b-input-group v-else prepend="Sport:" class="animated fadeIn">
            <b-form-select 
              v-model="selectedSport" 
              :options="sportOptions"
              class="w-auto"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <hr />
    </template>

    <!-- HEADER IMAGES -->
    <HeaderImageSelect 
      v-if="page.options?.featuredImages"
      v-model="page.options.featuredImages"
      :options="imageOpts"
    />
  
    <!-- ENTRY -->
    <b-row v-if="selectedPage">
      <b-col cols="12" md="9" class="animated fadeIn">
        <!-- TITLE -->
        <div role="group" class="mb-2">
          <label for="page-title" class="sr-only">Page Title:</label>
          <b-form-input 
            id="page-title" 
            size="lg"
            v-model="page.title" 
            maxlength="60"
            type="text" 
            placeholder="Title" 
            @blur="setNewSlug(page.title)"
          />
        </div>
        <!-- CONTENT -->
        <div role="group" class="mb-2">
          <label for="page-title" class="sr-only">Page:</label>
          <RichTextEditor :pageSlug="page.slug" :content="page.content" :placeholder="'Main content...'" @input="setContent" />
        </div>
        <hr />
        <!-- SUMMARY -->
        <div role="group" class="mb-2">
          <label for="page-title">SEO Description <small>(content summary)</small>:</label>
          <b-form-input 
            id="page-summary" 
            v-model="page.summary"
            maxlength="150"
            type="text" 
            placeholder="Search-Engine-Optimized Content Description"
          />
        </div>
        <!-- SLUG -->
        <div role="group" class="mb-2">
          <label for="page-slug" class="sr-only">Page Slug:</label>
          <b-input-group size="sm" prepend="/">
            <b-form-input 
              id="page-slug"
              v-model="page.slug"
              maxlength="60"
              type="text"
              placeholder="page-slug"
              :readonly="['local-areas', 'about'].includes(page.slug) || ['sport'].includes(selectedPage)"
              @keyup="setNewSlug(page.slug)"
            />
          </b-input-group>
        </div>
        <!-- SAVE -->
        <b-btn 
          variant="success" 
          @click="savePage()" 
          class="mb-2" 
          block
        >Save</b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import { formatTextAsHTML } from '@/utils/functions'
import HeaderImageSelect from '@/components/HeaderImageSelect'
import RichTextEditor from '@/components/RichTextEditor'

const defaultContent = {
  title: null,
  content: '',
  author: null,
  business: null,
  slug: '',
  parent: null
}
const STD_ENTRY_OPTIONS = {
  page: [
    { value: 'local-areas', text: '[ New Entry ] Local Areas' },
    { value: 'about', text: '[ New Entry ] About Us' },
    { value: 'sport', text: 'Sports We Play' }
  ]
}

export default {
  name: 'FormContent',
  components: { HeaderImageSelect, RichTextEditor },
  props: {
    type: {
      type: String,
      default: null
    },
    defaultSelectedPage: {
      type: String,
      default: null
    },
    action: {
      type: String,
      default: null
    },
    newOption: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedPage: null,
      selectedParentPage: null,
      selectedSport: null,
      page: {},
      selectedHeaderImages: [],
      hasHeaderSlider: false,
      imageOpts: []
    }
  },
  computed: {
    ...mapGetters(['pages', 'featuredImages']),
    /**
     * list of editable content options
     */
    contentOptions () {
      // @TODO nest child pages
      const options = this.pages.map(e => ({
        value: e.slug,
        text: e.title
      }))
      const stdEntries = STD_ENTRY_OPTIONS[this.type]
      if (stdEntries) {
        for (const i in stdEntries) {
          if (!options.find(e => (e.value === stdEntries[i].value))) {
            options.push(stdEntries[i])
          }
        }
      }
      if (this.newOption) options.unshift({ value: 'new', text: 'New Entry' })
      options.push({ value: null, text: 'Select an Entry', disabled: true })
      return options
    },
    /**
     * list of parent content options
     */
    parentContentOptions () {
      const options = this.pages.filter(e => (e.slug !== this.page.slug)).map(e => ({
        value: e['@id'],
        text: e.title
      }))
      options.unshift({ value: null, text: 'No Parent Page' })
      return options
    },
    sportOptions () {
      const options = this.$store.getters.sports
      .filter(s => this.currentBusiness.sports.includes(s['@id']))
      .map(e => ({
        value: e.slug,
        text: e.label
      }))
      options.unshift({ value: null, text: '----' })
      return options
    },
    /**
     * options for header images
     */
    // imageOpts () {
    //   // @FIXME
    //   let o = []
    //   if (!this.featuredImages.length) return o
    //   let featuredImages = [...this.featuredImages[0].images]
    //   for (let i in featuredImages) {
    //     let imgExists = false
    //     // let imgExists = this.selectedHeaderImages.findIndex((e,i) => e.src === featuredImages[i].src)
    //     for (let i2 in this.selectedHeaderImages) {
    //       if (featuredImages[i].src === this.selectedHeaderImages[i2].src) {
    //         imgExists = true
    //       }
    //     }
    //     if (!imgExists) {
    //       o.push(featuredImages[i])
    //     }
    //   }
    //   return o
    // },
    // hasHeaderSlider () {
    //   return ['about'].includes(this.page.slug)
    // }
  },
  
  watch: {
    'featuredImages': {
      handler () {
        this.imageOpts = this.setFeaturedImages()
      },
      immediate: true
    },
    selectedSport () {
      this.setSportContent()
    },
    '$route.params.slug' () {
      this.getBusinessPage()
    },
    '$route.query.page'() {
      this.page = { ...defaultContent }
      this.selectedPage = this.$route.query.page
    },
    hasHeaderSlider (val) {
      if (val) {
        this.getFeaturedImages({
          name: this.selectedPage
        })
      } else {
        // this.resetFeaturedImages()
      }
    },
    selectedPage (val) {
      if (val === 'about') {
        this.hasHeaderSlider = true
      } else {
        this.hasHeaderSlider = false
      }
    }
  },
  async mounted () {
    // load available pages
    await this.doGetPages()
    this.selectedPage = this.defaultSelectedPage
    this.page = {...defaultContent}

    // creating a new page
    if (this.$route.params.action && this.$route.params.action === 'new') {
      this.selectedPage = 'new'
      if (this.$route.params.slug) {
        // set parent page
        const parent = await this.getPage({
          business: this.currentBusiness.id,
          slug: this.$route.params.slug,
          type: this.type
        })
        if (parent) {
          this.selectedParentPage = parent['@id']
        } else if (this.contentOptions.find(e => e.value === this.$route.params.slug)) {
          // set new page option from slug
          this.selectedPage = this.$route.params.slug
        }
      }
    }

    // editing a page
    if (this.$route.params.action && this.$route.params.action === 'edit' && this.$route.params.slug) {
      this.selectedPage = this.$route.params.slug
    }
    if (this.$route.query.page && this.$route.query.page !== '') {
      this.selectedPage = this.$route.query.page
    }
    if (this.selectedPage && this.selectedPage !== 'new') {
      // load currently selected page
      await this.getBusinessPage()
    }
    // if (this.hasHeaderSlider) {
    //   await this.getFeaturedImages({
    //     name: this.selectedPage
    //   })
    // }
  },
  destroyed () {
    this.resetPages()
    this.resetFeaturedImages()
  },
  methods: {
    ...mapActions(['getPages', 'getPage','postPage','putPage', 'resetPages','getFeaturedImages', 'resetFeaturedImages']),
    setFeaturedImages () {
      // let o = []
      if (!(this.featuredImages.length && this.featuredImages[0].images)) return []
      return [...this.featuredImages[0].images]
      // let featuredImages = [...this.featuredImages[0].images]
      // for (let i in featuredImages) {
      //   let imgExists = false
      //   // let imgExists = this.selectedHeaderImages.findIndex((e,i) => e.src === featuredImages[i].src)
      //   for (let i2 in this.selectedHeaderImages) {
      //     if (featuredImages[i].src === this.selectedHeaderImages[i2].src) {
      //       imgExists = true
      //     }
      //   }
      //   if (!imgExists) {
      //     o.push(featuredImages[i])
      //   }
      // }
      // return o
    },
    setDefaultContent () {
      switch (this.selectedPage) {
        case 'about':
          this.page.title = 'About Us'
          this.page.slug = 'about'
          this.$set(this.page, 'options', { featuredImages: [] })
          break
        case 'local-areas':
          this.page.title = 'Local Areas'
          this.page.slug = 'local-areas'
          // this.page.content = 'Local Areas'
          break
      }
    },
    addSelectedImagesToPage () {
      if (this.selectedHeaderImages.length) {
        if (this.page.options === null) {
          this.page.options = {}
        }
        this.page.options.featuredImages = this.selectedHeaderImages
      }
    },
    setNewSlug (val) {
      if (
        ['about','local-areas'].includes(this.page.slug) || 
        ['sport'].includes(this.selectedPage)
      ) {
        return
      }
      // @TODO check for duplicate against existing entries!
      const slug = val || this.page.slug || this.page.title
      this.page.slug = this.toSlug(slug)
    },
    async savePage () {
      this.page.parent = this.selectedParentPage
      this.page.business = this.currentBusiness['@id']
      this.page.type = ['sport'].includes(this.selectedPage) ? 'sports-we-play' : 'page'
      this.page.author = this.currentUser['@id']
      this.setNewSlug()
      // this.addSelectedImagesToPage()
      if (this.page.id) {
        await this.putPage( this.page )
        // await this.doGetPages()
        this.showSuccess('page updated')
      } else {
        await this.postPage( this.page )
        this.page = this.$store.getters.page
        await this.doGetPages()
        if (!['sport'].includes(this.selectedPage)){
          this.selectedPage = this.page.slug
        }
        this.showSuccess('page saved')
      }
    },
    async getBusinessPage () {
      this.page = { ...defaultContent }
      this.selectedParentPage = null
      this.setDefaultContent()
      this.hasHeaderSlider = false
      if (this.selectedPage && this.selectedPage !== 'new') {
        this.hasHeaderSlider = (['about'].includes(this.selectedPage))
        const resp = await this.getPage({
          business: this.currentBusiness.id,
          slug: this.selectedPage,
          type: this.type
        })
        if (resp) {
          if (typeof resp.options?.featuredImages !== 'undefined') {
            await this.getFeaturedImages({
              name: this.selectedPage
            })
            this.setHeaderImagesSelected(resp.options.featuredImages)
            // this.hasHeaderSlider = true
          }
          this.page = this.$store.getters.page
          if (this.page.children) {
            this.page.children = this.page.children.map(e => (e['@id']))
          }
          if (this.page.parent) this.selectedParentPage = this.page.parent['@id']
          // set the sub directory name for images based on area name and current page slug
          // subDirectory = this.currentBusiness.uriName + '/' + this.page.slug
        }
      }
    },
    setHeaderImagesSelected (val) {
      this.selectedHeaderImages = val
    },
    setContent (content) {
      this.page.content = content
    },
    async doGetPages () {
      return await this.getPages({
        business: this.currentBusiness.id,
        pagination: false,
        type: this.type
      })
    },
    async setSportContent () {
      if (!this.selectedSport) { this.page = {} }
      let sport
      let content = await this.getPage({
        business: this.currentBusiness.id,
        slug: this.selectedSport,
        type: 'sports-we-play'
      })
      this.page.id = null
      if (!content) {
        sport = { ...this.$store.getters.sports.find(e => e.slug === this.selectedSport) }
        // sport.summary = formatTextAsHTML(sport.summary.replace(/^\s+|\s+$/g, ''))
      } else {
        this.page.id = content.id
        sport = {
          label: content.title,
          summary: content.content
        }
      }
      this.page.title = sport.label
      this.page.content = sport.summary
      this.page.slug = this.selectedSport
    }
  }
}
</script>
